import { Component, HostBinding ,OnInit } from '@angular/core';

import { User } from '../../models/User';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  job_to_delete : any = {};
  jobs: any = [];
  public user : User = {};

  public constructor(private jobsService:JobsService) {
    this.user = this.jobsService.sharedUserData;
    console.log(this.user);
   }

  ngOnInit() {
    this.getJobs();
  }

  getJobs() {
    this.jobsService.getJobs().subscribe(
      res => {
        console.log('Response for Jobs: ',res);
        this.jobs = res;
      },
      err => console.error(err)
    )
  }

  deleteJob(job_id:string) {
    this.jobsService.deleteJob(job_id).subscribe(
      res => {
        console.log(res);
        this.getJobs();
      },
      err => console.log(err)
    )
  }

}
