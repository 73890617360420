import { Component, HostBinding, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router} from '@angular/router';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  user:User = { };

  alert_popup : boolean = false;

  constructor(private authService:AuthService, private router: Router, private activatedRouter: ActivatedRoute,private jobsService:JobsService) { }

  ngOnInit(): void {
  }

  SignIn(){
    this.authService.signIn(this.user)
    .subscribe(
      (res : any)=> {
        if (res.token) {
          localStorage.setItem('token',res.token);
          this.jobsService.sharedUserData = res.user_data;
          console.log('This is the user data from Signin: ',res.user_data);
          this.router.navigate(['/jobs']);
        } else {
          this.alert_popup = true;
        }
      },
      err => console.error(err)
    )
  }

}
