import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { JobListComponent } from './components/job-list/job-list.component';
import { JobFormComponent } from './components/job-form/job-form.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { AuthGuard } from './auth.guard';
import { JobInfoComponent } from './components/job-info/job-info.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { CandidateInfoComponent } from './components/candidate-info/candidate-info.component';
import { HomepageComponent } from './components/homepage/homepage.component'

const routes: Routes = [
  {
    path: '',
    component: JobListComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'jobs',
    component: JobListComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'job/add',
    component : JobFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'job/edit/:job_id',
    component : JobFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'signin',
    component : SigninComponent
  },
  {
    path:'signup',
    component : SignupComponent
  },
  {
    path:'job/:job_id',
    component : JobInfoComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'applications/:job_id',
    component : ApplicationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'candidate/:id',
    component : CandidateInfoComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
