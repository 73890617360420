<nav class="navbar shadow fixed-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
        <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <a class="navbar-brand" routerLink="/"><img src="/assets/logo-tecontrato-2.png" alt="" width="148" height="45"></a>
              </div>
              <div class="col-8">
                <button class="navbar-toggler mr-0 float-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav01" aria-controls="navbarNav01" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon mr-0"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-md-end" id="navbarNav01">
                  <ul class="navbar-nav mr-0">
                    <ng-container *ngIf="!authService.loggedIn(); else loggedIn" >
                      <li class="nav-item">
                        <a class="nav-link" routerLink="/signin" routerLinkActive="active" aria-current="page"><i class="fas fa-sign-in-alt"></i> INGRESAR</a>
                      </li>
                    </ng-container>
                    <ng-template #loggedIn>
                      <li class="nav-item">
                        <a class="nav-link" routerLink="/jobs" routerLinkActive="active"><i class="fas fa-briefcase"></i> EMPLEOS</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" routerLink="/job/add" routerLinkActive="active"><i class="fas fa-plus-circle"></i> AGREGAR EMPLEO</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" style = "cursor: pointer;"(click)="authService.logOut()"><i class="fas fa-sign-out-alt"></i> SALIR</a>
                      </li>
                    </ng-template>
                  </ul>
                </div>
              </div>
          </div>
          </div>
        </div>
  </nav>

  <div class="navbar fixed-bottom shadow navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <div class="container-fluid">
        <p class="text-center">
          Copyright 2024 Te Contrato - Powered by <a href="https://www.cladirect.com" target="_blank" rel="noopener">CLAdirect Inc.</a>
        </p>
      </div>
    </div>
  </div>
