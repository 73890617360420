import { Component, HostBinding,OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.css']
})
export class CandidateInfoComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  public application:any = {};

  constructor(private applicationsService:ApplicationsService,private router: Router, private activatedRouter: ActivatedRoute) { }

  ngOnInit(): void {
    const params = this.activatedRouter.snapshot.params;
    if (params.id) {
      this.applicationsService.getApplication(params.id)
        .subscribe(
          res => {
            console.log(res);
            this.application = res;
          },
          err => console.error(err)
        )
    }
  }

  updateApplication(id:any,application:any) {
    this.applicationsService.updateApplication(id,application).subscribe(
      res => {
        console.log(res);
        this.router.navigate(['/applications/'+this.application.job_id]);
      },
      err => console.log(err)
    )
  }

}
