import { Component, HostBinding, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router} from '@angular/router';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  user:User = {
  }

  alert_popup : boolean = false;
  alert_popup2 : boolean = false;

  constructor(private authService:AuthService, private router: Router, private activatedRouter: ActivatedRoute,private jobsService:JobsService) { }

  ngOnInit(): void {
  }

  signUp() {
    this.authService.signUp(this.user)
    .subscribe(
      (res:any) => {
        if (res.token) {
          localStorage.setItem('token',res.token);
          this.router.navigate(['/jobs']);
        } else {
          if (res.text == "New lead created") {
            this.alert_popup2 = true;
          } else {
            this.alert_popup = true;
          }
        }
      },
      err => console.error(err)
      
    )
  }
}
