
  <div id="carouselExampleIndicators" class="carousel slide shadow" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block w-100" src="/assets/Slider1.png" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/Slider2.png" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="d-block w-100" src="/assets/Slider3.png" alt="Third slide">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div class="container shadow mt-1 bg-white">
    <div class="row">
        <div class="col">
            <br>
            <h3 class="text-center">Información General</h3>
            <p class="text-center">Te Contrato brinda el servicio de reclutamiento de candidatos para diferentes empresas por medio de nuestro reclutador virtual Charlie, que te ayudará a reclutar los mejores candidatos en diferentes plazas.</p>
            <p class="text-center">Nuestra plataforma cuenta con el registro de los candidatos a través de Facebook <a href="https://m.me/TeContratoGT" target="_blank" rel="noopener">Messenger</a>, Charlie se encarga de acompañar a los candidatos con 3 diferentes formularios a llenar: Información General, Académica y Experiencia Laboral.</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h3 class="text-center">Beneficios</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mt-1 mb-2">
            <div class="card text-center">
                <div class="card-header text-dark">
                    Charlie analiza todos los candidatos
                </div>
                <div class="card-body">
                    <p class="card-title">Nuestro asistente virtual Charlie analiza todos los curriculums y encuentra a los mejores candidatos para cada empleo</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
            <div class="card text-center">
                <div class="card-header text-dark">
                    Candidatos entrevistados
                </div>
                <div class="card-body">
                    <p class="card-title">Charlie realiza una precalificación y entrevista a los candidatos, los califica y te los presenta para su evaluación</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
            <div class="card text-center">
                <div class="card-header text-dark">
                    Seguimiento automático
                </div>
                <div class="card-body">
                    <p class="card-title">Charlie informa a los candidatos precalificados y les da el seguimiento adecuado hasta ser seleccionados</p>
                </div>
            </div>
        </div>
    </div>
  </div>


