import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_URI = 'https://qe5te1dk19.execute-api.us-west-1.amazonaws.com/beta/tecontrato-commander';

  constructor(private http: HttpClient, private router: Router) { }

  signUp(user_data: User) {
    return this.http.post(`${this.API_URI}/auth/signup`,user_data);
  }

  signIn(user_data: User) {
    return this.http.post(`${this.API_URI}/auth/signin`,user_data);
  }

  loggedIn():boolean {
    return !!localStorage.getItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logOut(){
    localStorage.removeItem('token');
    this.router.navigate(['/signin']);
  }
}
