import { Component, OnInit, HostBinding } from '@angular/core';
import { ApplicationsService } from '../../services/applications.service';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css']
})
export class ApplicationListComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  public applications:any = [];
  public application_to_delete:any = {};

  constructor(public applicationsService:ApplicationsService,private router: Router, private activatedRouter: ActivatedRoute) { }

  ngOnInit(): void {
    const params = this.activatedRouter.snapshot.params;
    if (params.job_id) {
      this.applicationsService.getApplications(params.job_id)
        .subscribe(
          res => {
            console.log(res);
            this.applications = res;
          },
          err => console.error(err)
        )
    }
  }

getApplications() {
  const params = this.activatedRouter.snapshot.params;
    if (params.job_id) {
      this.applicationsService.getApplications(params.job_id)
        .subscribe(
          res => {
            console.log(res);
            this.applications = res;
          },
          err => console.error(err)
        )
    }
}

  updateApplication(id:any,application:any) {
    this.applicationsService.updateApplication(id,application).subscribe(
      res => {
        console.log(res);
        this.getApplications();
      },
      err => console.log(err)
    )
  }

}
