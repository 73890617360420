
<div class="col-md-4 mt-1"*ngIf="jobs.length == 0">
    <div class="card shadow text-center">
        <div class="card-header text-dark">
            Aún no tienes empleos creados
        </div>
        <div class="card-body">
            <p class="card-title">Crea el primer empleo utilizando la opción Agregar Empleo</p>
        </div>
    </div>
</div>
<div class="row">
<div class="col-md-4 mt-1" *ngFor="let job of jobs; let i = index">
    <div class="card shadow text-center">
        <div class="card-header text-dark">
            {{job.nombre_plaza}}
        </div>
        <img [src]="job.image == undefined || job.image == '' ? '/assets/job-default-2.png' : job.image" class="card-img-top" >
        <div class="card-body">
            <p class="card-title text-truncate">{{job.descripcion_plaza}}</p>
            <button class="btn btn-light" [routerLink]="['/job/',job.job_id]">
                <i class="fas fa-info"></i>
            </button>
            <button class="btn btn-light" [routerLink]="['/job/edit',job.job_id]">
                <i class="fas fa-edit"></i>
            </button>
            <button type="button" (click)="job_to_delete = job" class="btn btn-light" data-toggle="modal" data-target="#exampleModal">
                <i class="fas fa-trash-alt"></i>
            </button>
        </div>
    </div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">Advertencia</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            ¿Está seguro que quiere eliminar el empleo {{job_to_delete.nombre_plaza}}?... si existieran candidatos en proceso de precalificación y selección serán notificados.
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" (click)="deleteJob(job_to_delete.job_id)" data-dismiss="modal">Eliminar</button>
        </div>
    </div>
    </div>
</div>

