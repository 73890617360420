<div class="col-md-6 offset-md-1">
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="jobForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label class="control-label required">Nombre:</label>
                    <input 
                        type="text" 
                        id="nombre_plaza"
                        placeholder="Nombre del empleo"
                        class="form-control" 
                        autofocus
                        formControlName="nombre_plaza"
                        required
                    />
                    <div *ngIf="validateField('nombre_plaza')" class="cross-validation-error-message alert alert-danger">
                        Debe ingresar un nombre para el empleo
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label required">Descripción del empleo:</label>
                    <textarea 
                        formControlName="descripcion_plaza" 
                        id="descripcion_plaza"
                        placeholder="Descripción" 
                        rows="2" 
                        class="form-control"
                        required 
                    ></textarea>
                    <div *ngIf="validateField('descripcion_plaza')" class="cross-validation-error-message alert alert-danger">
                        Debe ingresar una descripción para el empleo
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label required">Objetivo del puesto:</label>
                    <textarea 
                        formControlName="objetivo_puesto" 
                        id="objetivo_puesto"
                        placeholder="Objetivo del Puesto" 
                        rows="2" 
                        class="form-control"
                        required 
                    ></textarea>
                    <div *ngIf="validateField('objetivo_puesto')" class="cross-validation-error-message alert alert-danger">
                      Debe ingresar un objetivo para el empleo
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label required">Unidad de negocio:</label>
                  <input 
                      type="text" 
                      formControlName="analista_plaza" 
                      id="analista_plaza"
                      placeholder="Analista del empleo" 
                      class="form-control"
                  >
                  <div *ngIf="validateField('analista_plaza')" class="cross-validation-error-message alert alert-danger">
                    Debe ingresar el nombre del analista encargado
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label required">Grado académico:</label>
                  <div class="box" id="radios">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="grade1"
                          value=""
                          formControlName="grado_academico"
                        />
                        <label class="custom-control-label" for="grade1">Indiferente</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="grade2"
                          value="Primaria"
                          formControlName="grado_academico"
                        />
                        <label class="custom-control-label" for="grade2">Primaria</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="grade3"
                          value="Secundaria"
                          formControlName="grado_academico"
                        />
                        <label class="custom-control-label" for="grade3">Secundaria</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="grade4"
                          value="Prepa"
                          formControlName="grado_academico"
                        />
                        <label class="custom-control-label" for="grade4">Prepa</label>
                      </div>
                    </div>
                    <div *ngIf="validateField('grado_academico')" class="cross-validation-error-message alert alert-danger">
                      Debe seleccionar el grado académico del candidato
                    </div>
                </div>
                <div class="form-group">
                  <label class="control-label required">Experiencia laboral en seguridad privada</label>
                  <div class="box" id="radios2">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        class="custom-control-input"
                        type="radio"
                        id="exp3"
                        value=""
                        formControlName="tiene_experiencia_seguridad"
                      />
                      <label class="custom-control-label" for="exp3">Indiferente</label>
                    </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="exp1"
                          value="Si"
                          formControlName="tiene_experiencia_seguridad"
                        />
                        <label class="custom-control-label" for="exp1">Si</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="exp2"
                          value="No"
                          formControlName="tiene_experiencia_seguridad"
                        />
                        <label class="custom-control-label" for="exp2">No</label>
                      </div>
                    </div>
                    <div *ngIf="validateField('tiene_experiencia_seguridad')" class="cross-validation-error-message alert alert-danger">
                      Debe seleccionar si el candidato debe o no tener experiencia en seguridad
                    </div>
                </div>
                <div class="form-group">
                  <label class="control-label required">Labora actualmente como guardia</label>
                  <div class="box" id="radios3">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        class="custom-control-input"
                        type="radio"
                        id="exp13"
                        value=""
                        formControlName="labora_guardia"
                      />
                      <label class="custom-control-label" for="exp13">Indiferente</label>
                    </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="exp11"
                          value="Si"
                          formControlName="labora_guardia"
                        />
                        <label class="custom-control-label" for="exp11">Si</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="exp12"
                          value="No"
                          formControlName="labora_guardia"
                        />
                        <label class="custom-control-label" for="exp12">No</label>
                      </div>
                    </div>
                    <div *ngIf="validateField('labora_guardia')" class="cross-validation-error-message alert alert-danger">
                      Debe seleccionar si el debe laborar o no como guardia
                    </div>
                </div>
                <div class="row m-0">
                  <div class="col">
                    <h2 class="text-center">Perfil</h2>
                    <p class="text-center" style="color:#0084FF">Complete los campos que considere necesarios, si algún campo es indiferente para el puesto puede dejarlo en blanco o sin seleccionar.</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label">Principales Atribuciones:</label>
                  <textarea 
                      formControlName="principales_atribuciones" 
                      id="principales_atribuciones"
                      placeholder="Principales Atribuciones" 
                      rows="2" 
                      class="form-control"
                    ></textarea>
                </div>
                <div class="form-group">
                  <label class="control-label">Sexo:</label>
                  <div class="box" id="radios1">
                      <div class="custom-control custom-radio custom-control-inline">
                          <input
                            class="custom-control-input"
                            type="radio"
                            id="IndiferenteSexo"
                            value=""
                            formControlName="genero"
                          />
                          <label class="custom-control-label" for="IndiferenteSexo">Indiferente</label>
                        </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="Hombre"
                          value="Masculino"
                          formControlName="genero"
                        />
                        <label class="custom-control-label" for="Hombre">Masculino</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          class="custom-control-input"
                          type="radio"
                          id="Mujer"
                          value="Femenino"
                          formControlName="genero"
                        />
                        <label class="custom-control-label" for="Mujer">Femenino</label>
                      </div>
                    </div>
                </div>
                <div class="form-group">
                  <label class="control-label">Edad Mínima:</label>
                  <input 
                      type="number" 
                      formControlName="edad_minima" 
                      id="edad_minima"
                      min="18"
                      class="form-control"
                       
                  >
                </div>
                <div class="form-group">
                  <label class="control-label">Edad Máxima:</label>
                  <input 
                      type="number" 
                      formControlName="edad_maxima" 
                      id="edad_maxima"
                      max="90"
                      class="form-control"
                       
                  >
                </div>

                <div class="form-group">
                  <label class="control-label">Sueldo Inicial (en Pesos Mexicanos):</label>
                  <input 
                      type="number" 
                      formControlName="sueldo_Inicio" 
                      id="sueldo_Inicio"
                      placeholder="Sueldo Inicial"
                      class="form-control"
                       
                  >
                </div>
                <div class="row">
                  <div class="col">
                    <h4 class="text-center">Entrevista Preliminar</h4>
                  <p class="text-center" style="color: #0084FF">Charlie se encargará de hacer una entrevista preliminar a los candidatos, utilizando éstas preguntas, para que encuentre al candidato más idóneo.</p>
                  <p class="text-center" style="color: #0084FF">Las preguntas deben esperar respuestas afirmativas o negativas, Si o No.</p>
                  </div>
                </div>
                <div class="form-group">
                  <label>Pregunta 1:</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nombre_requisito1"
                    id="nombre_requisito1"
                  />
                  <label>Nivel de Importancia:</label>
                  <select
                  class="form-control"
                  formControlName="nivel_importanciaR1"
                >
                <option *ngFor="let nivelImp of preguntas_options" [value]="nivelImp" [selected]="(jobForm.controls.nivel_importanciaR1.value == null) ? false : (jobForm.controls.nivel_importanciaR1.value.includes(nivelImp)) ? true : false">{{nivelImp}}</option>
                </select>
                </div>
                <div class="form-group">
                  <label>Pregunta 2:</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nombre_requisito2"
                    id="nombre_requisito2"
                  />
                  <label>Nivel de Importancia:</label>
                  <select
                  class="form-control"
                  formControlName="nivel_importanciaR2"
                >
                <option *ngFor="let nivelImp of preguntas_options" [value]="nivelImp" [selected]="(jobForm.controls.nivel_importanciaR2.value ==  null) ? false : (jobForm.controls.nivel_importanciaR2.value.includes(nivelImp)) ? true : false">{{nivelImp}}</option>
                </select>
                </div>
                <div class="form-group">
                  <label>Pregunta 3:</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nombre_requisito3"
                    id="nombre_requisito3"
                  />
                  <label>Nivel de Importancia:</label>
                  <select
                  class="form-control"
                  formControlName="nivel_importanciaR3"
                >
                <option *ngFor="let nivelImp of preguntas_options" [value]="nivelImp" [selected]="(jobForm.controls.nivel_importanciaR3.value == null) ? false: (jobForm.controls.nivel_importanciaR3.value.includes(nivelImp)) ? true : false">{{nivelImp}}</option>
                </select>
                </div>
                <div class="form-group">
                  <label>Pregunta 4:</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nombre_requisito4"
                    id="nombre_requisito4"
                  />
                  <label>Nivel de Importancia:</label>
                  <select
                  class="form-control"
                  formControlName="nivel_importanciaR4"
                >
                <option *ngFor="let nivelImp of preguntas_options" [value]="nivelImp" [selected]="(jobForm.controls.nivel_importanciaR4.value == null) ? false : (jobForm.controls.nivel_importanciaR4.value.includes(nivelImp)) ? true : false">{{nivelImp}}</option>
                </select>
                </div>
                <div class="form-group">
                  <label>Pregunta 5:</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nombre_requisito5"
                    id="nombre_requisito5"
                  />
                  <label>Nivel de Importancia:</label>
                  <select
                  class="form-control"
                  formControlName="nivel_importanciaR5"
                >
                <option *ngFor="let nivelImp of preguntas_options" [value]="nivelImp" [selected]="(jobForm.controls.nivel_importanciaR5.value == null) ? false : (jobForm.controls.nivel_importanciaR5.value.includes(nivelImp)) ? true : false">{{nivelImp}}</option>
                </select>
                </div>
                <br>
                <div class="row">
                  <button type="button" class="btn btn-success btn-block" data-toggle="modal" data-target="#exampleModal" [disabled]="!jobForm.valid">
                    Guardar
                </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="col-md-4">
    <div class="card shadow card-body text-center">
      <div class="card-header text-dark">
        {{jobForm.controls.nombre_plaza.value == '' ? 'Nombre Plaza' : jobForm.controls.nombre_plaza.value }}
      </div>
      <img [src]="jobForm.controls.image.value == '' ? '/assets/job-default-1.jpg' : jobForm.controls.image.value" class="card-img-top" >
      <div class="card-body">
        <p>{{jobForm.controls.descripcion_plaza.value == '' ? 'Descripción' : jobForm.controls.descripcion_plaza.value}}</p>
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
  <div class="modal-content">
      <div class="modal-header">
      <h5 class="modal-title text-center" id="exampleModalLabel">Advertencia</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
      </div>
      <div class="modal-body" *ngIf="!edit" >
          ¿Está seguro que quiere guardar el empleo {{jobForm.controls.nombre_plaza.value}}?... al Guardar el empleo Charlie iniciará el proceso de búsqueda de candidatos, los candidatos que precalifiquen serán notificados y entrevistados por Charlie.
      </div>
      <div class="modal-body" *ngIf="edit" >
        ¿Está seguro que quiere modificar el empleo {{jobForm.controls.nombre_plaza.value}}?... al Guardar el empleo Charlie iniciará el proceso de búsqueda de candidatos nuevamente, los candidatos que precalifiquen serán notificados y entrevistados por Charlie, los candidatos ya precalificados o en entrevista continuarán con el proceso.
    </div>
      <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
      <button type="button" class="btn btn-success" (click)="onSubmit()" data-dismiss="modal">Guardar</button>
      </div>
  </div>
  </div>
</div>
