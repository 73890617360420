<div class="col-md-8 offset-md-2" >
    <div class="card shadow bg-light text-center">
        <div class="card-header text-dark align-items-center">
            {{job.nombre_plaza}}
        </div>
        <img [src]="job.image == undefined || job.image == '' ? '/assets/job-default-2.png' : job.image" class="card-img-top" >
        <div class="card-body">
            <div *ngIf="applications.length==0; else hasCandidates">
                <button class="btn btn-light" (click)="popup==true ? popup=false : popup=true">
                    <i class="fas fa-user-tie"></i> Candidatos <span class="badge badge-dark">{{applications.length}}</span>
                </button>
                <div class="alert alert-info" role="alert" *ngIf="popup">
                    Aún no hay candidatos que cumplan con todos los requerimientos
                </div>
            </div>
            <ng-template #hasCandidates>
                <button class="btn btn-light" [routerLink]="['/applications',job.job_id]">
                    <i class="fas fa-user-tie"></i> Candidatos <span class="badge badge-success">{{applications.length}}</span>
                </button>
            </ng-template>
            <table>
                <tr><td><h6 class="card-title">Descripción:</h6> </td><td><p>{{job.descripcion_plaza}}</p></td></tr>
                <tr><td><h6 class="card-title">Objetivo:</h6></td><td><p>{{job.objetivo_puesto}}</p></td></tr>
                <tr><td><h6 class="card-title">Unidad de negocio:</h6></td><td><p>{{job.analista_plaza}}</p></td></tr>
                <tr><td><h6 class="card-title">Grado académico:</h6></td><td><p>{{job.grado_academico}}</p></td></tr>
                <tr><td><h6 class="card-title">Experiancia como guardia:</h6></td><td><p>{{job.tiene_experiencia_seguridad}}</p></td></tr>
                <tr><td><h6 class="card-title">Trabaja como guardia:</h6></td><td><p>{{job.labora_guardia}}</p></td></tr>
                <tr><td><h6 class="card-title">Atribuciones:</h6></td><td><p>{{job.principales_atribuciones}}</p></td></tr>
                <tr><td><h6 class="card-title">Sexo:</h6> </td><td><p>{{job.genero}}</p></td></tr>
                <tr><td><h6 class="card-title">Edad Mínima:</h6></td><td><p>{{job.edad_minima}}</p></td></tr>
                <tr><td><h6 class="card-title">Edad Máxima:</h6></td><td><p>{{job.edad_maxima}}</p></td></tr>
                <tr><td><h6 class="card-title">Sueldo Inicial:</h6></td><td><p>{{job.sueldo_Inicio}}</p></td></tr>
            </table>
            <div *ngIf="!(job.nombre_requisito1=='' || job.nombre_requisito1==null)" >
                <div class="row">
                    <div class="col">
                      <h4 class="text-center">Entrevista Preliminar</h4>
                    </div>
                </div>
                <table>
                    <tr *ngIf="!(job.nombre_requisito1=='' || job.nombre_requisito1==null || job.nombre_requisito1=='null')"><td><h6 class="card-title">Pregunta 1:</h6></td><td><p>{{job.nombre_requisito1}}</p></td></tr>
                    <tr *ngIf="!(job.nivel_importanciaR1=='' || job.nivel_importanciaR1==null || job.nivel_importanciaR1=='null')"><td><h6 class="card-title">Importancia Pregunta 1:</h6> </td><td><p>{{job.nivel_importanciaR1}}</p></td></tr>
                    <tr *ngIf="!(job.nombre_requisito2=='' || job.nombre_requisito2==null || job.nombre_requisito2=='null')"><td><h6 class="card-title">Pregunta 2:</h6></td><td><p>{{job.nombre_requisito2}}</p></td></tr>
                    <tr *ngIf="!(job.nivel_importanciaR2=='' || job.nivel_importanciaR2==null || job.nivel_importanciaR2=='null')"><td><h6 class="card-title">Importancia Pregunta 2:</h6> </td><td><p>{{job.nivel_importanciaR2}}</p></td></tr>
                    <tr *ngIf="!(job.nombre_requisito3=='' || job.nombre_requisito3==null || job.nombre_requisito3=='null')"><td><h6 class="card-title">Pregunta 3:</h6></td><td><p>{{job.nombre_requisito3}}</p></td></tr>
                    <tr *ngIf="!(job.nivel_importanciaR3=='' || job.nivel_importanciaR3==null || job.nivel_importanciaR3=='null')"><td><h6 class="card-title">Importancia Pregunta 3:</h6> </td><td><p>{{job.nivel_importanciaR3}}</p></td></tr>
                    <tr *ngIf="!(job.nombre_requisito4=='' || job.nombre_requisito4==null || job.nombre_requisito4=='null')"><td><h6 class="card-title">Pregunta 4:</h6></td><td><p>{{job.nombre_requisito4}}</p></td></tr>
                    <tr *ngIf="!(job.nivel_importanciaR4=='' || job.nivel_importanciaR4==null || job.nivel_importanciaR4=='null')"><td><h6 class="card-title">Importancia Pregunta 4:</h6></td><td><p>{{job.nivel_importanciaR4}}</p></td></tr>
                    <tr *ngIf="!(job.nombre_requisito5=='' || job.nombre_requisito5==null || job.nombre_requisito5=='null')" ><td><h6 class="card-title">Pregunta 5:</h6></td><td><p>{{job.nombre_requisito5}}</p></td></tr>
                    <tr *ngIf="!(job.nivel_importanciaR5=='' || job.nivel_importanciaR5==null || job.nivel_importanciaR5=='null')"><td><h6 class="card-title">Importancia Pregunta 5:</h6></td><td><p>{{job.nivel_importanciaR5}}</p></td></tr>
                </table>
            </div>
            <button class="btn btn-light" [routerLink]="['/job/edit',job.job_id]">
                <i class="fas fa-edit"></i>
            </button>
            <button type="button" class="btn btn-light" data-toggle="modal" data-target="#exampleModal">
                <i class="fas fa-trash-alt"></i>
            </button>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">Advertencia</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            ¿Está seguro que quiere eliminar el empleo {{job.nombre_plaza}}?... si existieran candidatos en proceso de precalificación y selección serán notificados.
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" (click)="deleteJob(job.job_id)" data-dismiss="modal">Eliminar</button>
        </div>
    </div>
    </div>
</div>
