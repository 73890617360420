import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  API_URI = 'https://qe5te1dk19.execute-api.us-west-1.amazonaws.com/beta/tecontrato-commander';

  constructor(private http: HttpClient) { }

  getApplications(job_id: string | undefined) {
    return this.http.get(`${this.API_URI}/applications/${job_id}`);
  }

  getApplication(id: string | undefined) {
    return this.http.get(`${this.API_URI}/applications/application/${id}`);
  }

  updateApplication(id: string | undefined,application:any) {
    return this.http.put(`${this.API_URI}/applications/application/${id}`,application);
  }
}
