import { Component, HostBinding ,OnInit } from '@angular/core';
import { Job } from '../../models/Job';
import { User } from '../../models/User';
import { ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, Validators} from '@angular/forms';

import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.css']
})

export class JobFormComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  job:any = {
    id:0,
    job_id:'',
    customer_id:'',
    nombre_plaza:'',
    descripcion_plaza:'',
    image:'',
    profesion_oficio:''
  }

  public user : User = {};

  edit:boolean = false;

  jobForm = this.formBuilder.group({
    nombre_plaza: ['', [Validators.required]],
    descripcion_plaza: ['', [Validators.required]],
    objetivo_puesto: ['', [Validators.required]],
    profesion_oficio: [''],
    analista_plaza: ['', [Validators.required]],
    fecha_limite: [''],
    tiene_experiencia: [''],
    grado_academico: [''],
    tiene_experiencia_seguridad: [''],
    labora_guardia: [''],
    area_exp: [''],
    experiencia_minima: [''],
    experiencia_maxima: [''],
    principales_atribuciones: [''],
    genero: [''],
    edad_minima: [''],
    edad_maxima: [''],
    estado_civil: [''],
    estudio_superior: [''],
    estudia_actual: [''],
    estudio_maestria: [''],
    idioma_ingles: [''],
    sueldo_Inicio: [''],
    viajar_interior: [''],
    viajar_exterior: [''],
    manejo_personal: [''],
    nombre_requisito1: [''],
    nivel_importanciaR1: [''],
    nombre_requisito2: [''],
    nivel_importanciaR2: [''],
    nombre_requisito3: [''],
    nivel_importanciaR3: [''],
    nombre_requisito4: [''],
    nivel_importanciaR4: [''],
    nombre_requisito5: [''],
    nivel_importanciaR5: [''],
    image: ['']
  });

  public idioma_ingles_options : any = ['No es necesario','Bajo','Medio','Alto'];

  public gradoOpciones : any = [
    'Indiferente',
    'Primaria',
    'Secundaria',
    'Diversificado',
    'Otro',
  ]
  public profesion_oficio_options : any = [
      "Abogado",
      "Administrador",
      "Analista Legal",
      "Arquitecto",
      "Auditor",
      "Bachiller",
      "Community Manager",
      "Comunicador",
      "Desarrollador",
      "Dibujante",
      "Diseñador",
      "Electricista",
      "Enfermero",
      "Financiero",
      "Ingeniero Agrónomo",
      "Ingeniero Agroindustrial",
      "Ingeniero Ambiental",
      "Ingeniero Civil",
      "Ingeniero Electricista",
      "Ingeniero Electrónico",
      "Ingeniero Forestal",
      "Ingeniero Industrial",
      "Ingeniero Mecánico",
      "Ingeniero Mecatrónico",
      "Ingeniero en Alimentos",
      "Ingeniero en Sistemas",
      "Químico Biólogo",
      "Químico Farmacéutico",
      "Licenciatura en Informática",
      "Maestro de Educación",
      "Maestro de obras",
      "Mecánico",
      "Médico",
      "Mercadólogo",
      "Perito Contador",
      "Plomero",
      "Programador",
      "Psicólogo",
      "Publicista",
      "Recursos Humanos",
      "Secretaria",
      "Social Media",
      "Supervisor",
      "Técnico",
      "Tercero Básico",
      "Ventas",
      "Otro",
  ];
  
  public area_exp_options : any = [
    "Administración",
    "Agroindustria",
    "Agronomia",
    "Arquitectura",
    "Auditoría",
    "Banca",
    "Bodega",
    "Bufetes Legales",
    "Caja",
    "Call Center",
    "Cobros",
    "Compras",
    "Comunicación",
    "Construcción",
    "Consultoría",
    "Contabilidad",
    "Créditos",
    "Diseño",
    "Educación",
    "Electricidad",
    "Enfermería",
    "Finanzas",
    "Gerencia",
    "Hotelería",
    "Jefaturas",
    "Laboratorios",
    "Logística",
    "Mecánica",
    "Medicina",
    "Mercadeo",
    "Operativos",
    "Piloto",
    "Planta de Producción",
    "Plomería",
    "Promotores",
    "Publicidad",
    "Receptores",
    "Recursos Humanos",
    "Redes Sociales",
    "Restaurantes",
    "Servicio al cliente",
    "Soporte técnico",
    "Supervisión",
    "Tecnología",
    "Ventas"
  ]

  public estado_civil_options : any = [
    "Soltero/a",
    "Casado/a",
    "Viudo/a",
    "Divorciado/a",
    "Unido/a",
  ]

  public nivel_ingles_options : any = [
    "Bajo",
    "Medio",
    "Alto",
  ]

  public preguntas_options : any = [
    "Baja",
    "Media",
    "Alta",
  ]

  public constructor(private jobsService: JobsService, private router: Router, private activatedRouter: ActivatedRoute,private formBuilder: FormBuilder) {
   }


  ngOnInit(){
    const params = this.activatedRouter.snapshot.params;
    if (params.job_id) {
      this.jobsService.getJob(params.job_id)
        .subscribe(
          (res: any) => {
            console.log(res);
            this.job = res;
            this.jobForm.patchValue(res);
            this.edit = true;
          },
          (err: any) => console.error(err)
          
        )
    }
  }

  saveNewJob() {
    delete this.job.created_at;
    delete this.job.id;
    this.job.job_status = "active";
    if (Array.isArray(this.jobForm.value.profesion_oficio)) {
      this.jobForm.value.profesion_oficio = JSON.stringify(this.jobForm.value.profesion_oficio);
    }
    if (Array.isArray(this.jobForm.value.area_exp)){
      this.jobForm.value.area_exp = JSON.stringify(this.jobForm.value.area_exp);
    }
    console.log('This is the Job to be saved: ',this.job);
    this.jobsService.saveJob(this.job)
    .subscribe(
      (res: any) => {
        console.log(res);
        this.router.navigate(['/jobs']);
      },
      (err: any) => console.error(err)
    )
  }

  updateJob() {
    delete this.job.created_at;
    if (Array.isArray(this.jobForm.value.profesion_oficio)) {
      this.jobForm.value.profesion_oficio = JSON.stringify(this.jobForm.value.profesion_oficio);
    }
    if (Array.isArray(this.jobForm.value.area_exp)){
      this.jobForm.value.area_exp = JSON.stringify(this.jobForm.value.area_exp);
    }
    this.jobsService.updateJob(this.job.job_id,this.jobForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.router.navigate(['/jobs'])
        },
        (err: any) => console.error(err)
      )
  }

  validateField(fieldname:string):boolean{
    const validation = (this.jobForm.controls[fieldname].invalid && (this.jobForm.controls[fieldname].touched || this.jobForm.controls[fieldname].dirty));
    return validation;
  }

  onSubmit() {
    console.warn(this.jobForm.value);
    if (this.jobForm.valid) {
      if (this.edit) {
        this.updateJob();
      } else {
        this.job = this.jobForm.value;
        this.saveNewJob();
      }
    } else {
      console.log("Form not valid");
      Object.keys(this.jobForm.controls).forEach(field => { 
        const control : any = this.jobForm.get(field);            
        control.markAsTouched({ onlySelf: true });       
      });
    }
  }

  experiencia_change() {
    if (this.jobForm.controls.tiene_experiencia.value == 'Si') {
      console.log('tiene experiencia change set: Si');
      this.jobForm.controls.area_exp.setValidators([Validators.required]);
      this.jobForm.controls.experiencia_minima.setValidators([Validators.required]);
      this.jobForm.controls.experiencia_maxima.setValidators([Validators.required]);
    } else {
      console.log('tiene experiencia change set: No');
      this.jobForm.controls.area_exp.setValidators([]);
      this.jobForm.controls.experiencia_minima.setValidators([]);
      this.jobForm.controls.experiencia_maxima.setValidators([]);
      this.jobForm.controls.area_exp.setValue("");
      this.jobForm.controls.experiencia_minima.setValue("");
      this.jobForm.controls.experiencia_maxima.setValue("");
    }
  } 

}
