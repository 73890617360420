import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,FormBuilder,Validators,ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { JobFormComponent } from './components/job-form/job-form.component';
import { JobListComponent } from './components/job-list/job-list.component';
import {TokenInterceptorService} from './services/token-interceptor.service'

import { JobsService } from './services/jobs.service';
import { SignoutComponent } from './components/signout/signout.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { AuthGuard } from './auth.guard';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { JobInfoComponent } from './components/job-info/job-info.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { CandidateInfoComponent } from './components/candidate-info/candidate-info.component';
import { HomepageComponent } from './components/homepage/homepage.component'


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    JobFormComponent,
    JobListComponent,
    SignoutComponent,
    SigninComponent,
    SignupComponent,
    FieldErrorDisplayComponent,
    JobInfoComponent,
    ApplicationListComponent,
    CandidateInfoComponent,
    HomepageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    JobsService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    FormBuilder,
    Validators
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
