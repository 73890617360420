<div class="col-md-8 offset-md-2" >
    <div class="card shadow bg-light text-center">
        <div class="card-header text-dark align-items-center">
            {{application.primer_nombre+' '+application.primer_apellido}}
        </div>
        <div class="card-body">
            <p class="card-title">Calificación: <span [class] = "application.candidate_score < 61 ? 'badge badge-secondary' : 'badge badge-success'">{{application.candidate_score}}</span></p>
            <div *ngIf="application.application_status=='candidate_accepted'">
                <p class="card-title">Estatus: <span class="badge badge-secondary">Interesado</span></p>
                <div class="alert alert-info" role="alert">
                    El candidato ha aceptado aplicar, pero aún no completa la entrevista
                </div>
            </div>
            <div *ngIf="application.application_status=='form_completed'">
                <p class="card-title">Estatus: <span class="badge badge-primary">Entrevistado</span></p>
                <div class="alert alert-info" role="alert">
                    El candidato ya ha sido entrevistado
                </div>
            </div>
            <div *ngIf="application.application_status=='candidate_selected'">
                <p class="card-title">Estatus: <span class="badge badge-success">Seleccionado</span></p>
                <div class="alert alert-info" role="alert">
                    El candidato ha sido seleccionado para avanzar en el proceso
                </div>
            </div>
            <div *ngIf="application.application_status=='prequalified'">
                <p class="card-title">Estatus: <span class="badge badge-secondary">Precalificado</span></p>
                <div class="alert alert-info" role="alert">
                    El candidato ha sido precalificado, pero aún no acepta continuar con el proceso de selección.
                </div>
            </div>
            <div *ngIf="application.application_status=='notified_1'">
                <p class="card-title">Estatus: <span class="badge badge-secondary">Notificado</span></p>
                <div class="alert alert-info" role="alert">
                    El candidato ha sido precalificado y notificado, pero aún no acepta continuar con el proceso de selección.
                </div>
            </div>
            <div *ngIf="application.application_status=='form_completed' || application.application_status=='candidate_selected'">
                <h6>Entrevista:</h6>
                <table>
                    <tr *ngIf="!(application.nombre_requisito1=='' || application.nombre_requisito1==null || application.nombre_requisito1=='null')"><td ><p>{{application.nombre_requisito1}}</p> </td><td><p>{{application.respuesta_requisito1}}</p></td></tr>
                    <tr *ngIf="!(application.nombre_requisito2=='' || application.nombre_requisito2==null || application.nombre_requisito2=='null')"><td ><p>{{application.nombre_requisito2}}</p> </td><td><p>{{application.respuesta_requisito2}}</p></td></tr>
                    <tr *ngIf="!(application.nombre_requisito3=='' || application.nombre_requisito3==null || application.nombre_requisito3=='null')"><td ><p>{{application.nombre_requisito3}}</p> </td><td><p>{{application.respuesta_requisito3}}</p></td></tr>
                    <tr *ngIf="!(application.nombre_requisito4=='' || application.nombre_requisito4==null || application.nombre_requisito4=='null')"><td ><p>{{application.nombre_requisito4}}</p> </td><td><p>{{application.respuesta_requisito4}}</p></td></tr>
                    <tr *ngIf="!(application.nombre_requisito5=='' || application.nombre_requisito5==null || application.nombre_requisito5=='null')"><td ><p>{{application.nombre_requisito5}}</p> </td><td><p>{{application.respuesta_requisito5}}</p></td></tr>
                </table>
            </div>
            <h4>Información General</h4>
            <table>
                <tr><td><h6 class="card-title">Primer Nombre:</h6> </td><td><p>{{application.primer_nombre}}</p></td></tr>
                <tr><td><h6 class="card-title">Segundo Nombre:</h6></td><td><p>{{application.segundo_nombre}}</p></td></tr>
                <tr><td><h6 class="card-title">Primer Apellido:</h6> </td><td><p>{{application.primer_apellido}}</p></td></tr>
                <tr><td><h6 class="card-title">Segundo Apellido:</h6></td><td><p>{{application.segundo_apellido}}</p></td></tr>
                <tr *ngIf="!(application.apellido_casada=='' || application.apellido_casada==null)"><td><h6 class="card-title">Apellido de Casada:</h6> </td><td><p>{{application.apellido_casada}}</p></td></tr>
                <tr *ngIf="!(application.numero_telefono=='' || application.numero_telefono==null)"><td><h6 class="card-title">Número de Teléfono:</h6></td><td><p>{{application.numero_telefono}}</p></td></tr>
                <tr><td><h6 class="card-title">Sexo:</h6> </td><td><p>{{application.genero}}</p></td></tr>
                <tr><td><h6 class="card-title">Dirección:</h6> </td><td><p>{{application.nombre_direccion}}</p></td></tr>
                <tr><td><h6 class="card-title">Grado Académico</h6></td><td><p>{{application.grado_academico}}</p></td></tr>
                <tr><td><h6 class="card-title">Experiencia laboral en seguridad privada</h6></td><td><p>{{application.tiene_experiencia_seguridad}}</p></td></tr>
                <tr><td><h6 class="card-title">Labora como guardia:</h6> </td><td><p>{{application.labora_guardia}}</p></td></tr>
            </table>
            <button class="btn btn-light" class="btn btn-light" data-toggle="modal" data-target="#exampleModal2">
                <i class="fas fa-check-circle"></i>
            </button>
            <button class="btn btn-light" class="btn btn-light" data-toggle="modal" data-target="#exampleModal">
                <i class="fas fa-eye-slash"></i>
            </button>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">Advertencia</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            ¿Está seguro que quiere descartar al candidato {{application.primer_nombre+' '+application.primer_apellido}}?...
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-danger" (click)="updateApplication(application.id,{'application_status':'closed_not_selected'})" data-dismiss="modal">Descartar</button>
        </div>
    </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel2">Advertencia</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            ¿Está seguro que quiere seleccionar al candidato {{application.primer_nombre+' '+application.primer_apellido}} para avanzar en el proceso?...
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" (click)="updateApplication(application.id,{'application_status':'candidate_selected'})" data-dismiss="modal">Seleccionar</button>
        </div>
    </div>
    </div>
</div>