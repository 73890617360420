import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Job } from '../models/Job'
import { Customer } from '../models/Customer';
import { User } from '../models/User';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  userData$: Observable<any>;
  private myMethodSubject = new Subject<any>();
  API_URI = 'https://qe5te1dk19.execute-api.us-west-1.amazonaws.com/beta/tecontrato-commander';

  sharedUserData!: User; 

  constructor(private http: HttpClient) {
    this.userData$ = this.myMethodSubject.asObservable();
   }

  getJobs() {
    return this.http.get(`${this.API_URI}/jobs`);
  }

  getJob(job_id: string) {
    return this.http.get(`${this.API_URI}/jobs/job/${job_id}`);
  }

  deleteJob(job_id: string) {
    return this.http.delete(`${this.API_URI}/jobs/job/${job_id}`);
  }

  saveJob(job:Job) {
    return this.http.post(`${this.API_URI}/jobs`,job);
  }

  updateJob(job_id: string|number|undefined,updatedJob: Job) {
    return this.http.put(`${this.API_URI}/jobs/job/${job_id}`,updatedJob);
  }

  userData(user_data:User) {
    console.log('Here is the data in the Service: '+user_data);
    this.myMethodSubject.next(user_data);
  }

  signUp(user_data: User) {
    return this.http.post(`${this.API_URI}/auth/signup`,user_data);
  }
}
