import { Component, OnInit } from '@angular/core';

import { JobsService } from '../../services/jobs.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router} from '@angular/router';



@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {


  public constructor(private jobsService:JobsService, public authService: AuthService, private router: Router, private activatedRouter: ActivatedRoute) { 

  }

  ngOnInit(): void {
    
  }

}
