import { Component, HostBinding,OnInit } from '@angular/core';
import { Job } from '../../models/Job';
import { Customer } from '../../models/Customer';
import { ActivatedRoute, Router} from '@angular/router';
import { JobsService } from '../../services/jobs.service';
import { ApplicationsService } from '../../services/applications.service';

@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.css']
})
export class JobInfoComponent implements OnInit {

  @HostBinding('class') classes = 'row';

  job:any= {
    id:0,
    job_id:'',
    customer_id:'',
    nombre_plaza:'',
    descripcion_plaza:'',
    objetivo_puesto: '',
    profesion_oficio: '',
    analista_plaza: '',
    tiene_experiencia: '',
    area_exp: '',
    experiencia_minima:'',
    experiencia_maxima: '',
    principales_atribuciones: '',
    genero: '',
    edad_minima: '',
    edad_maxima: '',
    estado_civil: '',
    estudio_superior: '',
    estudia_actual: '',
    estudio_maestria: '',
    idioma_ingles: '',
    sueldo_Inicio: '',
    viajar_interior: '',
    viajar_exterior: '',
    manejo_personal: '',
    nombre_requisito1: '',
    nivel_importanciaR1: '',
    nombre_requisito2: '',
    nivel_importanciaR2: '',
    nombre_requisito3: '',
    nivel_importanciaR3: '',
    nombre_requisito4: '',
    nivel_importanciaR4: '',
    nombre_requisito5: '',
    nivel_importanciaR5: '',
    image:''
  }

  popup:boolean | undefined;

  public applications:any = [];
  
  public customer : Customer = {};

  edit:boolean = false;

  constructor(private jobsService: JobsService, private router: Router, private activatedRouter: ActivatedRoute, private applicationsService: ApplicationsService) { }

  ngOnInit(): void {
    const params = this.activatedRouter.snapshot.params;
    if (params.job_id) {
      this.jobsService.getJob(params.job_id)
        .subscribe(
          res => {
            console.log(res);
            this.job = res;
          },
          err => console.error(err)
        )
      this.applicationsService.getApplications(params.job_id)
        .subscribe(
          res => {
            console.log(res);
            this.applications = res;
          },
          err => console.error(err)
        )
    }
  }
  deleteJob(job_id:any) {
    this.jobsService.deleteJob(job_id).subscribe(
      res => {
        console.log(res);
        this.router.navigate(['/jobs']);
      },
      err => console.log(err)
    )
  }
}
