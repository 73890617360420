
    <div class="col-md-4 mt-3 mx-auto">
        <div class="card shadow">
            <div class="card-header text-center">
                EMPRESAS
            </div>
            <div class="card-body">
                <div class="container">
                    <form (submit)="signUp()">

                            <div class="form-group">
                                <input type="email" name="email" [(ngModel)]="user.correo_electronico" class="form-control" placeholder="Email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #email="ngModel" autofocus>
                            </div>
                            <div *ngIf="email.touched">
                                <div *ngIf="email.errors?.required"class="cross-validation-error-message alert alert-danger">
                                   Un email es requerido
                                </div>
                                <div *ngIf="email.errors?.pattern"class="cross-validation-error-message alert alert-danger">
                                    Ingresar un email válido
                                </div>
                              </div>

                            <div class="form-group">
                                <input type="text" name="primer_nombre" [(ngModel)]="user.primer_nombre" class="form-control" placeholder="Nombre" required>
                            </div>

                            <div class="form-group">
                                <input type="text" name="primer_apellido" [(ngModel)]="user.primer_apellido" class="form-control" placeholder="Apellido" required>
                            </div>

                            <div class="form-group">
                                <input type="password" name="password" [(ngModel)]="user.password" class="form-control" placeholder="Password" required >
                            </div>

                            <button class="btn btn-dark btn-block">
                                Registrarse
                            </button>

                        <div *ngIf="alert_popup" class="cross-validation-error-message alert alert-danger">
                            Ya existe un usuario registrado con los mismos datos
                        </div>
                        <div *ngIf="alert_popup2" class="cross-validation-error-message alert alert-info">
                            Gracias se ha creado tu usuario, uno de nuestros asesores estará en contacto contigo pronto.  También nos puedes escribir a <a href="mailto:info@tecontrato.net">info@tecontrato.net</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 mt-3 mx-auto">
        <div class="card shadow mt-1">
            <div class="card-header text-center">
                CANDIDATOS
            </div>
            <div class="card-body">
                <div class="container">
                        <div>
                            <p class="text-center">Si eres candidato puedes registrarte hablando con Charlie, nuestro reclutador virtual en Facebook <a href="https://m.me/TeContratoGT" target="_blank" rel="noopener">Messenger</a></p>
                        </div>
                </div>
            </div>
        </div>
    </div>

