
    <div class="col-md-4 mt-3 mx-auto">
        <div class="card shadow mt-1" >
            <div class="card-header text-center">
                EMPRESAS
            </div>
            <div class="card-body">
                <div class="container">
                    <form (submit)="SignIn()">

                        <div class="form-group" (change)="alert_popup=false">
                            <input type="email" name="email" [(ngModel)]="user.correo_electronico" class="form-control" placeholder="Email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #email="ngModel" autofocus>
                        </div>
                        <div *ngIf="email.touched">
                            <div *ngIf="email.errors?.required"class="cross-validation-error-message alert alert-danger">
                                Un email es requerido
                            </div>
                            <div *ngIf="email.errors?.pattern"class="cross-validation-error-message alert alert-danger">
                                Ingresar un email válido
                            </div>
                            </div>

                        <div class="form-group" (change)="alert_popup=false">
                            <input type="password" name="password" [(ngModel)]="user.password" class="form-control" placeholder="Password" required >
                        </div>

                        <button class="btn btn-dark btn-block">
                            Ingresar
                        </button>

                        <div *ngIf="alert_popup" class="cross-validation-error-message alert alert-danger">
                            El email o password son incorrectos
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
        <div class="col-md-4 mt-3 mx-auto">
            <div class="card shadow mt-1">
                <div class="card-header text-center">
                    CANDIDATOS
                </div>
                <div class="card-body">
                    <div class="container">

                        <div>
                            <p class="text-center">Si eres candidato puedes Ingresar hablando con nuestro reclutador virtual en <a href="https://wa.me/525520320344" target="_blank" rel="noopener">WhatsApp</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>


